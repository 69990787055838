.App {
  text-align: center;
}

.Workspace {

}

.leaflet-container {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}
.leaflet-heatmap-layer {
  z-index: 100;
}
